import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { CtaBlock } from 'stile-shared/src/components/2020/blocks/CtaBlock';
import { P } from 'stile-shared/src/components/2020/common/Primitives';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { ResourceAccessBlock } from 'components/common/ResourceAccessBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="The Stile Guide"
      description="The essential guide to supercharge your science teaching with Stile"
      slug={props.location.pathname}
    />
  );
}

function StileGuidePage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <ResourceAccessBlock
        title="The Stile Guide"
        subtitle="The essential guide to supercharge your science teaching with Stile"
        id="STILE_GUIDE"
        redirectUrl="http://stileapp.com/go/stileguide"
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/resources/Stile_Guide_tile.jpg"
            alt=""
          />
        }
      >
        <P>
          Stile is a purpose-built teaching tool, designed to provide you with everything you need
          to deliver brilliant, engaging science lessons. No longer do you need to spend hours
          making your own resources! The Stile Guide is your go-to resource to learn the ins and
          outs of Stile.
        </P>
        <P>
          We love a reliable, repeatable experiment. That’s why we’ve written these articles in the
          same tried and tested format, starting with an introduction and ending with useful
          information.
        </P>
      </ResourceAccessBlock>

      <CtaBlock />
    </PageLayout>
  );
}

export default StileGuidePage;
